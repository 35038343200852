import Lottie from "lottie-react";
import React from 'react';
import groovyWalkAnimation from "./rocket-light.json";

function App() {


  return <div>
    <Lottie animationData={groovyWalkAnimation} loop={true} style={{ height: 600 }} />
    <h1 className="text-3xl font-bold underline text-center text-slate-900">
      Something beautiful is landing soon!
    </h1>
  </div>;
}

export default App;
